<template>
  <v-container id="urban_renewal-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('urbanRenewal.title')"
      class="px-5 py-3 font-weight-medium"
    >
      <v-col class="text-right">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-dialog
          v-model="dialog_form" 
          max-width="500px"
          persistent
          v-if="role === UserRole.CompanyAdmin"
          >
          <template v-slot:activator="{ on }">
              <v-btn color="primary" class="mx-1 mb-2" v-on="on" @click="getRelations"
                >{{ $t("company_mgr.assign") }}
              </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("company_mgr.assign") }}</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <template  v-for="urban in urbans">
                  <v-divider></v-divider>
                  <v-select
                    class="assign-select"
                    :key="urban.id"
                    v-model="relation_map[urban.id]"
                    :items="users"
                    :menu-props="{ maxHeight: '400' }"
                    :label="urban.name"
                    :rules="relationRules"
                    item-value="id"
                    multiple
                    hide-details
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.username }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.nickname }}({{ data.item.username }})
                    </template></v-select>
                </template>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                @click="close"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                :disabled="loading"
                @click="saveAssign"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          color="primary"
          class="mb-2"
          @click="checkQuota()"
        >
          <v-icon left>mdi-pencil-plus</v-icon>{{ $t("urbanRenewal.createUrban") }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.name="{ item }">
          <v-flex>
            {{ item.name }}
            <template v-if="alarms.find(obj=>{ return obj.urban === item.id })">
              <error-dialog :datas="alarms.find(obj=>{ return obj.urban === item.id }).items" />
            </template>
          </v-flex>
        </template>
        <template v-slot:item.land_area="{ item }">
          <v-flex>
            {{ areaFormat(item.land_area) }}
          </v-flex>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item)"
            >{{ $t("common.profile") }}</v-btn>
            <v-btn
              color="ocean"
              class="mx-2"
              small
              @click="showOwnerList(item)"
            >{{ $t("common.owner") }}</v-btn>
            <v-btn
              color="navy"
              class="mx-2"
              small
              @click="showCommonPartList(item)"
            >{{ $t("urbanRenewal.commonpart") }}</v-btn>
            <v-btn color="red" class="mx-2" small
            @click="RemoveUrban(item)"
            >{{
              $t("common.delete")
            }}</v-btn>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import CommonUtil from "@/utils/commonutil.js";
import { UserRole } from '@/definition'
import ErrorDialog from '../common/ErrorDialog'
export default {
  data: vm => ({
    dialog_message: false,
    valid: true,
    items: [],
    totalItems: 0,
    loading: false,
    dialog_form: false,
    options: {},
    message: {
      title: "",
      text: ""
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("urbanRenewal.name"),
        value: "name"
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("urbanRenewal.city_name"),
      //   value: "city_name"
      // },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("urbanRenewal.area_name"),
      //   value: "area_name"
      // },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("urbanRenewal.region_name"),
      //   value: "region_name"
      // },
      {
        sortable: false,
        text: vm.$i18n.t("urbanRenewal.land_area"),
        value: "land_area"
      },
      {
        sortable: false,
        text: vm.$i18n.t("urbanRenewal.owner_count"),
        value: "owner_count"
      },
      {
        sortable: false,
        text: vm.$i18n.t("urbanRenewal.contact_name"),
        value: "contact_name"
      },
      {
        sortable: false,
        text: vm.$i18n.t("urbanRenewal.contact_phone"),
        value: "contact_phone"
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions"
      }
    ],
    item: {
      name: null,
      city_name: null,
      area_name: null,
      region_name: null,
      cdr_code: null,
      building_area: null,
      land_area: null,
      owner_count: null,
      contact_name: null,
      contact_phone: null,
      contact_address: null,
    },
    relationRules: [v => v.length!=0 || vm.$i18n.t("common.missing")],
    UserRole: UserRole,
    users: [],
    urbans: [],
    relations: [],
    relation_map: {},
    alarms: [],
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
  }),
  components: {
    ErrorDialog: ErrorDialog,
  },
  computed: {
    ...mapState(["token", "max_urban_renewal", "role"])
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  methods: {
    ...mapMutations(["setTitle", "setQuota"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;      
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
            page: page,
            page_size: itemsPerPage,
        }
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = currentObj.$t(error.response.data.detail);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getAlarm() {
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/alarm/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.setAlarm(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if(this.items.length == this.max_urban_renewal){
        alert(this.$i18n.t('urbanRenewal.reachMaxCount'))
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      let data = {
        name: this.item.name,
        city_name: this.item.city_name,
        area_name: this.item.area_name,
        region_name: this.item.region_name,
        cdr_code: this.item.cdr_code,
        building_area: this.item.building_area,
        land_area: this.item.land_area,
        owner_count: this.item.owner_count,
        contact_name: this.item.contact_name,
        contact_phone: this.item.contact_phone,
        contact_address: this.item.contact_address
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.$refs.form.reset()
          currentObj.close();
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data["results"];
    },
    setAlarm(data) {
      let results = data.results
      let alarms = []
      results.forEach(item=>{
        let owner
        let urban = item.urban_renewal.id
        if(item.building_relation){
          owner = item.building_relation.owner
        } else {
          owner = item.land_relation.owner
        }
        let targetUrban = alarms.find(obj=>{ return obj.urban === urban })
        if (targetUrban) {
          let targetOwner = targetUrban.items.find(obj=>{obj.owner === owner})
          if (!targetOwner) {
            targetUrban.items.push(item)
          }
        } else {
          alarms.push({
            urban: urban,
            items: [item]
          })
        }
      })
      this.alarms = alarms
    },
    checkQuota(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/getQuota/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setQuota(response.data);
          currentObj.createUrban(response.data)
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$i18n.t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    createUrban(data){
      // if(this.items.length == this.max_urban_renewal){
      //   alert(this.$i18n.t('urbanRenewal.reachMaxCount'))
      //   return;
      // }
      if(data['max_urban_renewal'] < this.totalItems+1){
        this.message.title = this.$i18n.t("common.warning");
        this.message.text = this.$i18n.t("urbanRenewal.outOfQuota");
        this.dialog_message = !this.dialog_message;
        return
      }
      this.$router.push({
        name: "UrbanRenewalDetail",
        query: {
          item: null
        }
      });
    },
    showDetail(item) {
      this.$router.push({
        name: "UrbanRenewalDetail",
        query: {
          item: item.id
        }
      });
    },
    showOwnerList(item) {
      this.$router.push({
        name: "OwnerList",
        query: {
          item: item.id,
          urban_name: item.name
        }
      });
    },
    showCommonPartList(item){
      this.$router.push({
        name: "CommonPartList",
        query: {
          item: item.id
        }
      });
    },
    RemoveUrban(item){
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/urban/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, {}, config)
        .then(function(response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    areaFormat(area){
      return CommonUtil.areaFormat(area)
    },
    getRelations(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/getRelations/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          // currentObj.setResult(response.data);
          console.log(response.data);
          currentObj.setRelations(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setRelations(data){
      this.users = data.users
      this.urbans = data.urbans
      this.relation_map = {}
      this.urbans.forEach(element=>{
        this.relation_map[element.id] = []
        let user_relation = data.relations.filter(item=>{ return item.urban_renewal__id == element.id && item.is_active == true })
        user_relation.forEach(relation=>{
          this.relation_map[element.id].push(relation.user__id)
        })
      })
      this.relations = []
      data.relations.forEach(element => {
        let newItem = {
          'id': element.id,
          'urban_renewal': element.urban_renewal__id,
          'user': element.user__id,
          'is_active': element.is_active,
        }
        this.relations.push(newItem)
      });
    },
    getAssignData(){
      let urbanKeys = Object.keys(this.relation_map)
      urbanKeys.forEach(key=>{
        let existRelation = this.relations.filter(item=>{ return item.urban_renewal == key })
        this.changeAssignData(key, existRelation)
      })
    },
    changeAssignData(key, existRelation){
      let userList = this.relation_map[key]
      let existUsers = []
      existRelation.forEach(element=>{
        existUsers.push(element.user)
      })
      this.users.forEach(user=>{
        if(existUsers.indexOf(user.id) != -1){
          let existData = this.relations.find(item=>{ return item.urban_renewal == Number(key) && item.user == user.id })
          if(existData){
            if(userList.indexOf(user.id) != -1){
              existData.is_active = true
            } else {
              existData.is_active = false
            }
          }
        } else {
          if(userList.indexOf(user.id) != -1){
            let newItem = {
              'id': null,
              'urban_renewal': Number(key),
              'user': user.id,
              'is_active': true,
            }
            this.relations.push(newItem)
          }
        }
      })
      this.relations = this.relations.filter(item=>{ return (item.id == null && item.is_active == true) || item.id != null })
    },
    saveAssign(){
      if (!this.$refs.form.validate()) {
        return;
      }
      this.getAssignData()

      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/assignUrban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      let data = {
        relations: this.relations
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.close();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.UrbanRenewal"));
    this.getApi();
    this.getAlarm();
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  }
};
</script>
<style lang="sass" scoped>
.assign-select
  width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  ::v-deep .v-label
    font-size: 16px!important
    height: 26px!important
    line-height: 26px!important
</style>
