import { render, staticRenderFns } from "./UrbanRenewal.vue?vue&type=template&id=3db93db8&scoped=true"
import script from "./UrbanRenewal.vue?vue&type=script&lang=js"
export * from "./UrbanRenewal.vue?vue&type=script&lang=js"
import style0 from "./UrbanRenewal.vue?vue&type=style&index=0&id=3db93db8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db93db8",
  null
  
)

export default component.exports